import React, { useState } from "react"
import Footer from "../components/Footer"
import NavigationBar from "../components/NavigationBar"
import Jumbotron from "../components/Jumbotron"
import styled from "styled-components"
import { Form, Button, Row, Col, Container } from "react-bootstrap"
import { Helmet } from "react-helmet"

const header = "Contact Us"
const pageSummary = "Get in touch with us."

const StyledContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const ContentDiv = styled.div`
  flex-grow: 1;
  text-align: justify;
  text-justify: auto;
`

const ContactUsPage = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  })

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => alert("Thanks for your message!"))
      .catch(error => alert(error))

    e.preventDefault()
  }

  return (
    <StyledContainer>
      <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={pageSummary} />
      <title>Lomond Roads Cycling Club - Contact Us</title>
      <link rel="canonical" href="lomondroads.org/ContactUs" />
    </Helmet>
      <NavigationBar />
      <Jumbotron header={header} pageSummary={pageSummary} />
      <ContentDiv>
        <Container>
          <Row>
            <Col>
              <p>
                If you want to get in touch with us then please fill in the
                details below. You should recieve an e-mail reply in response
                within a few days if this is applicable.
              </p>
              <Form
                onSubmit={handleSubmit}
                name="contact"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
              >
                <Form.Group controlId="contactFormName">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Your name"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    value={formState.name}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="contactFormEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={formState.email}
                    placeholder="Your e-mail address"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="contactFormMessage">
                  <Form.Label>Message:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="5"
                    name="message"
                    id="message"
                    placeholder="Your message"
                    onChange={handleChange}
                    value={formState.message}
                    required
                  />
                </Form.Group>
                <Button type="submit">Submit</Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </ContentDiv>
      <Footer />
    </StyledContainer>
  )
}
export default ContactUsPage
